
import ApiService from "@/core/services/ApiService";
import { defineComponent, onMounted, ref, computed, watch } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import Notice from "@/components/Notice.vue";
import moment from "moment";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Avertissement",
  components: {
    Notice,
  },
  setup(props) {
    const { t, locale } = useI18n();
    const avertissementsData = ref([]);
    const body = ref(
      `${t("avertissement.informations")} <br><a href="#" class="fw-bolder">${t(
        "avertissement.informations2"
      )}</a>`
    );

    watch(
      () => locale.value,
      (newd, prevCount) => {
        body.value = `${t(
          "avertissement.informations"
        )} <br><a href="#" class="fw-bolder">${t(
          "avertissement.informations2"
        )}</a>`;
      }
    );
    onMounted(() => {
      setCurrentPageTitle("dashboard.avertissements");
    });
    ApiService.setHeader();
    ApiService.post("/studentPunishment/filter", {
      query: {
        student: window.localStorage.getItem("activeStudent"),
        validated: "validated",
      },
    })
      .then(({ data }) => {
        console.log(data);
        console.log(moment().format("YYYYDDMM"));
        avertissementsData.value = data;
      })
      .catch((e) => console.log(e));

    const filtredData = computed(() => {
      let rep = avertissementsData?.value?.filter((avertissement) => {
        return (
          moment(avertissement[9]).format("YYYY-MM-DD") <
            moment().format("YYYYDDMM") &&
          moment(avertissement[9]).format("YYYY-MM-DD") >
            moment().subtract(7, "d").format("YYYYDDMM")
        );
      });

      console.log("filterd:", new Set(rep));
      return new Set(rep);
    });

    return {
      body,
      avertissementsData,
      moment,
      filtredData,
      t,
    };
  },
});
